import React from 'react'
import { Link, graphql, StaticQuery } from 'gatsby'
import Slider from 'react-slick'
import Equalizer from './Equalizer'
import classnames from 'classnames'
import moment from 'moment'
import get from 'lodash/get'
import { Date as PrismicDate } from 'prismic-reactjs'

import arrow from '../images/cta-arrow.svg'
import GatsbyImage from 'gatsby-image'

function Button({ slideCount, currentSlide, className, isPrev, ...props }) {
  return (
    <button
      {...props}
      className={classnames(
        'hidden lg:block absolute carousel-button p-4 z-50',
        {
          'carousel-button--prev': isPrev,
        }
      )}
    >
      <img
        src={arrow}
        alt=""
        className="block h-6"
        style={{ transform: isPrev ? 'rotate(180deg)' : 'none' }}
      />
    </button>
  )
}

const slickSettings = {
  dots: false,
  infinite: false,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 3,
  appendArrows: false,
  nextArrow: <Button />,
  prevArrow: <Button isPrev />,
  responsive: [
    {
      breakpoint: 992,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
}

export default class HomePodcasts extends React.PureComponent {
  state = {
    current: 0,
    yolo: 0,
  }

  constructor(props) {
    super(props)

    if (typeof window !== 'undefined') {
      this.media = window.matchMedia('screen and (min-width: 900px)')
    }
  }

  afterChange = index => {
    this.setState({ current: index })
  }

  getNodesForEqualizer(nodePrefix) {
    return Object.getOwnPropertyNames(this)
      .filter(property => property.indexOf(nodePrefix) === 0)
      .map(property => this[property])
  }

  render() {
    const query = graphql`
      query {
        allFile(filter: { name: { glob: "expert__photo--*" } }) {
          edges {
            node {
              name
              childImageSharp {
                fluid(maxWidth: 128, quality: 85) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
        }
      }
    `

    return (
      <StaticQuery
        query={query}
        render={data => {
          const expertPhotos = data.allFile.edges.map(edge => edge.node)
          return (
            <div className="mb-16 text-blue-dianne">
              <div className="container px-8 lg:max-w-xl mx-auto mt-24 lg:mt-32">
                <h2 className="font-serif text-center text-blue-dianne font-normal text-xl lg:text-2xl">
                  {this.props.sectionTitle || 'Our Podcasts'}
                </h2>
              </div>
              <Equalizer
                byRow={false}
                nodes={() => this.getNodesForEqualizer('nameNode')}
                enabled={() => this.media.matches}
              >
                <Equalizer
                  byRow={false}
                  nodes={() => this.getNodesForEqualizer('titleNode')}
                  enabled={() => this.media.matches}
                >
                  <Equalizer
                    byRow={false}
                    nodes={() => this.getNodesForEqualizer('headerNode')}
                    enabled={() => this.media.matches}
                  >
                    <Slider
                      {...{
                        ...slickSettings,
                        ...{
                          dots:
                            this.props.limit === 3 ? false : slickSettings.dots,
                        },
                      }}
                      afterChange={this.afterChange}
                      onInit={() =>
                        this.setState(state => ({ yolo: state.yolo + 1 }))
                      }
                    >
                      {(this.props.podcasts || []).map((podcast, index) => (
                        <Slide
                          className="w-1/3 px-8 lg:px-2"
                          index={index}
                          key={index}
                          current={this.state.current}
                        >
                          <Interview
                            nameRef={node => (this[`nameNode${index}`] = node)}
                            titleRef={node =>
                              (this[`titleNode${index}`] = node)
                            }
                            headerRef={node =>
                              (this[`headerNode${index}`] = node)
                            }
                            avatar={get(
                              expertPhotos.find(
                                photo =>
                                  photo.name ===
                                  `expert__photo--${podcast.expert._meta.uid}`
                              ),
                              'childImageSharp.fluid',
                              null
                            )}
                            name={podcast.expert.name}
                            title={podcast.title}
                            focusArea={podcast.focusarea}
                            uid={podcast._meta.uid}
                            expertTitle={podcast.expert.title}
                            createdAt={podcast._meta.firstPublicationDate}
                            index={
                              this.props.overrideIndex === undefined
                                ? index
                                : this.props.overrideIndex
                            }
                          />
                        </Slide>
                      ))}
                    </Slider>
                  </Equalizer>
                </Equalizer>
              </Equalizer>
              {this.props.podcasts && this.props.showAllLink && (
                <div className="flex mx-8 lg:mx-auto mt-16 justify-center">
                  <Link
                    to={this.props.allLink || '/podcasts'}
                    className="font-serif text-lg text-blue-dianne bg-selago px-8 py-5 flex items-center no-underline"
                  >
                    <span className="cta-ie11-fix">Show all Podcasts</span>{' '}
                    <img src={arrow} alt="" className="ml-8 h-6" />
                  </Link>
                </div>
              )}
            </div>
          )
        }}
      />
    )
  }
}

export function Slide({ children, style, index, current, ...props }) {
  return (
    <div
      style={{
        ...style,
        outline: 'none',
      }}
      {...props}
    >
      {children}
    </div>
  )
}

export function Interview({
  index,
  nameRef,
  titleRef,
  headerRef,
  name,
  avatar,
  title,
  expertTitle,
  focusArea,
  createdAt,
  uid,
}) {
  return (
    <div className="mt-24">
      <div className="bg-selago pb-16 lg:px-8 mt-24 pt-8">
        <div className="lg:flex">
          <div className="w-48 lg:w-32 flex-no-shrink mx-auto lg:mx-0">
            <Link
              to={`/podcast${uid ? `/${uid}` : ''}`}
              className="block no-underline text-blue-dianne"
            >
              {avatar ? (
                <GatsbyImage fluid={avatar} />
              ) : (
                <img src={avatar} alt="" className="block" />
              )}
            </Link>
          </div>
          <div className="px-8 lg:pl-8 lg:pr-0 pt-8">
            <p
              className="font-serif px-8 lg:px-0 text-lg font-normal"
              ref={nameRef}
            >
              <Link
                to={`/podcast${uid ? `/${uid}` : ''}`}
                className="no-underline text-blue-dianne"
              >
                {name}
              </Link>
            </p>
            <p className="font-sans px-8 lg:px-0 mt-4 lg:mt-4" ref={titleRef}>
              <Link
                to={`/podcast${uid ? `/${uid}` : ''}`}
                className="no-underline text-blue-dianne"
              >
                {expertTitle}
              </Link>
            </p>
          </div>
        </div>
      </div>
      <div
        className={classnames(
          'bg-white p-8 measure mx-auto lg:mx-8 lg:-mt-8 border-t-4',
          focusArea
            ? `border-${focusArea._meta.uid}`
            : {
                'border-age-science': index === 0,
                'border-preventive-healthcare': index === 1,
                'border-longevity-economy': index === 2,
              }
        )}
      >
        <p className="font-sans text-gothic px-8 lg:px-0 mb-4 text-sm">
          {moment(PrismicDate(createdAt)).format('MMMM DD, YYYY')}
        </p>
        <p className="font-serif text-lg2" ref={headerRef}>
          <Link
            to={`/podcast${uid ? `/${uid}` : ''}`}
            className="no-underline text-blue-dianne"
          >
            {title}
          </Link>
        </p>
        <Link
          to={`/podcast${uid ? `/${uid}` : ''}`}
          className="block mt-8 font-sans text-gothic text-sm uppercase no-underline"
        >
          Learn more
        </Link>
      </div>
    </div>
  )
}
