import React from 'react'
import { Link, graphql } from 'gatsby'
import classnames from 'classnames'
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  EmailShareButton,
} from 'react-share'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faFacebookF,
  faLinkedinIn,
  faTwitter,
} from '@fortawesome/free-brands-svg-icons'
import { faEnvelope, faPrint, faTimes } from '@fortawesome/free-solid-svg-icons'
import moment from 'moment'
import AriaModal from 'react-aria-modal'
import { Date, RichText } from 'prismic-reactjs'
import { createRichTextSerializer } from '../utils/prismic'

import Layout from '../components/layout'
import SEO from '../components/seo'

import quoteIcon from '../images/quote.svg'
import HomePodcasts from '../components/HomePodcasts'

export const query = graphql`
  query($uid: String!, $focusAreaId: String!) {
    prismic {
      podcast(uid: $uid, lang: "en-us") {
        _meta {
          uid
        }
        title
        focusarea {
          ... on PRISMIC_Focus_area {
            _meta {
              uid
            }
            name
          }
        }
        author {
          ... on PRISMIC_Article_author {
            name
            title
            photo
          }
        }
        body {
          ... on PRISMIC_PodcastBodyText {
            type
            primary {
              text
            }
          }
          ... on PRISMIC_PodcastBodyQuote {
            type
            primary {
              quote
            }
          }
          ... on PRISMIC_PodcastBodyImage {
            type
            primary {
              image
              caption
            }
          }
          ... on PRISMIC_PodcastBodyPodcast {
            type
            primary {
              soundcloud_embed
            }
          }
          ... on PRISMIC_PodcastBodyExpert_quote {
            type
            primary {
              quote_expert {
                ... on PRISMIC_Expert {
                  name
                  title
                  short_bio
                  full_bio
                  photo
                }
              }
              expert_quote_layout
              quote
            }
          }
        }
        expert {
          ... on PRISMIC_Expert {
            name
            title
            short_bio
            full_bio
            photo
          }
        }
        highlights {
          highlight_text
        }
        _meta {
          firstPublicationDate
        }
      }

      latestPodcasts: allPodcasts(
        sortBy: meta_firstPublicationDate_DESC
        first: 20
        where: { focusarea: $focusAreaId }
      ) {
        edges {
          node {
            _meta {
              uid
              firstPublicationDate
            }
            title
            is_hidden
            focusarea {
              ... on PRISMIC_Focus_area {
                _meta {
                  uid
                  id
                }
                name
              }
            }
            expert {
              ... on PRISMIC_Expert {
                _meta {
                  uid
                }
                name
                title
                photo
              }
            }
          }
        }
      }
    }
  }
`

const articleSerializer = createRichTextSerializer({
  paragraphClassname: () => 'mt-6 mx-8 lg:mx-0 text-lg',
  heading2Classname: () => 'mx-8 lg:mx-0 mt-8 -mb-2',
})

const fullBioSerializer = createRichTextSerializer({
  paragraphClassname: ({ index }) =>
    classnames('font-sans leading-loose text-base text-blue-dianne', {
      'mt-4': index === 0,
      'mt-2': index > 0,
    }),
  aClassname: () =>
    'text-blue-dianne font-sane leading-loose text-base hover:no-underline',
})

class ArticlePage extends React.PureComponent {
  state = {
    modalActive: false,
    mobileBioActive: false,
    expert: undefined,
  }

  showModal = () => {
    this.setState({ modalActive: true })
  }

  hideModal = () => {
    this.setState({ modalActive: false })
  }

  showMobileBio = () => {
    this.setState({ mobileBioActive: true })
  }

  hideMobileBio = () => {
    this.setState({ mobileBioActive: false })
  }

  getApplicationNode = () => {
    if (document) {
      return document.getElementById('___gatsby')
    }
  }

  showBioModal = expert => {
    this.setState({ modalActive: true, expert })
  }

  render() {
    const {
      data: {
        prismic: { podcast: article, latestPodcasts: latestPodcastsRaw },
      },
    } = this.props

    if (!article) {
      return null
    }

    const latestPodcasts = latestPodcastsRaw.edges
      .map(edge => edge.node)
      .filter(
        latestPodcast =>
          latestPodcast._meta.uid !== article._meta.uid &&
          latestPodcast.is_hidden !== 'HIDDEN'
      )
      .slice(0, 3)

    let quoteIndex = -1

    return (
      <Layout stickyMenu>
        <SEO title={article.title} keywords={[`longevity`]} />

        <div className="container lg:max-w-xl mx-auto mt-24 text-blue-dianne border-dusty-gray lg:pb-16 mb-16 lg:mb-32 border-b">
          <div className="lg:flex items-start justify-between">
            <div className="measure text-lg">
              <div className="flex items-center mx-8 lg:mx-0">
                <FacebookShareButton
                  url={
                    typeof window === 'undefined'
                      ? 'https://l-institute.com'
                      : window.location.href
                  }
                  quote={article.title}
                >
                  <span className="block flex items-center justify-center">
                    <FontAwesomeIcon
                      icon={faFacebookF}
                      className="text-blue-dianne text-base"
                      size="xs"
                      style={{ maxWidth: 20 }}
                      fixedWidth
                    />
                  </span>
                </FacebookShareButton>
                <LinkedinShareButton
                  url={
                    typeof window === 'undefined'
                      ? 'https://l-institute.com'
                      : window.location.href
                  }
                >
                  <span className="block flex items-center justify-center">
                    <FontAwesomeIcon
                      icon={faLinkedinIn}
                      className="text-blue-dianne text-base"
                      size="xs"
                      style={{ maxWidth: 20 }}
                      fixedWidth
                    />
                  </span>
                </LinkedinShareButton>
                <TwitterShareButton
                  url={
                    typeof window === 'undefined'
                      ? 'https://l-institute.com'
                      : window.location.href
                  }
                  title={article.title}
                >
                  <span className="block flex items-center justify-center">
                    <FontAwesomeIcon
                      icon={faTwitter}
                      className="text-blue-dianne text-base"
                      size="xs"
                      style={{ maxWidth: 20 }}
                      fixedWidth
                    />
                  </span>
                </TwitterShareButton>
                <button
                  onClick={() => window.print()}
                  className="block mr-8 flex items-center justify-center"
                >
                  <FontAwesomeIcon
                    icon={faPrint}
                    className="text-blue-dianne text-base"
                    size="xs"
                    style={{ maxWidth: 20 }}
                    fixedWidth
                  />
                </button>
                <EmailShareButton
                  url={
                    typeof window === 'undefined'
                      ? 'https://l-institute.com'
                      : window.location.href
                  }
                  subject={article.title}
                >
                  <span className="block flex items-center justify-center">
                    <FontAwesomeIcon
                      icon={faEnvelope}
                      className="text-blue-dianne text-base"
                      size="xs"
                      style={{ maxWidth: 20 }}
                      fixedWidth
                    />
                  </span>
                </EmailShareButton>
              </div>
              <p className="text-xs mb-8 font-sans uppercase mx-8 lg:mx-0 mt-8">
                {moment(Date(article._meta.firstPublicationDate)).format(
                  'MMMM DD, YYYY'
                )}
                <span className="mx-2">&bull;</span>
                <Link
                  to="/podcasts"
                  className={classnames(
                    'no-underline hover:underline font-bold',
                    `text-${article.focusarea._meta.uid}`
                  )}
                >
                  {article.focusarea.name}
                </Link>
              </p>
              <h1 className="text-2xl font-serif font-normal mx-8 lg:mx-0">
                {article.title}
              </h1>
              {article.body && (
                <article className="copy">
                  {article.body.map((slice, index) => {
                    if (slice.type === 'text') {
                      return (
                        <React.Fragment key={index}>
                          {RichText.render(
                            slice.primary.text,
                            undefined,
                            articleSerializer
                          )}
                        </React.Fragment>
                      )
                    }

                    if (slice.type === 'expert_quote') {
                      quoteIndex += 1
                      return (
                        <div className="my-16 lg:my-24" key={index}>
                          {slice.primary.quote_expert && (
                            <div
                              className={classnames(
                                'ml-2 mr-2 items-start lg:mr-12 flex',
                                {
                                  'flex-row-reverse':
                                    slice.primary.expert_quote_layout !==
                                    'Left',
                                }
                              )}
                            >
                              {slice.primary.quote_expert.photo && (
                                <div
                                  className={classnames('flex-no-shrink', {
                                    'mr-4 lg:mr-8':
                                      slice.primary.expert_quote_layout ===
                                      'Left',
                                    'ml-4 lg:ml-8':
                                      slice.primary.expert_quote_layout !==
                                      'Left',
                                  })}
                                >
                                  <img
                                    src={slice.primary.quote_expert.photo.url}
                                    alt=" "
                                    className="w-12 lg:w-24"
                                  />
                                </div>
                              )}
                              <div
                                className={classnames({
                                  'text-right':
                                    slice.primary.expert_quote_layout !==
                                    'Left',
                                })}
                              >
                                <p className="font-serif text-lg lg:text-xl">
                                  {slice.primary.quote_expert.name}
                                </p>
                                <p className="font-sans leading-normal text-base mt-2">
                                  {slice.primary.quote_expert.short_bio}
                                </p>
                                <button
                                  onClick={() =>
                                    this.showBioModal(
                                      slice.primary.quote_expert
                                    )
                                  }
                                  className={classnames(
                                    'hidden lg:block mt-4 font-sans text-blue-dianne text-sm uppercase no-underline focus:outline-none',
                                    {
                                      'ml-auto':
                                        slice.primary.expert_quote_layout !==
                                        'Left',
                                    }
                                  )}
                                >
                                  Read bio
                                </button>
                              </div>
                            </div>
                          )}
                          <div className="flex items-start ml-2 mr-2 lg:mr-12 mt-8 py-8 px-4 lg:p-8 text-base font-sans bg-selago-80 text-blue-dianne">
                            {[0, 1, 2].includes(quoteIndex) && (
                              <img
                                src={quoteIcon}
                                alt=""
                                className="block w-6 mb-2 mr-4 lg:-mt-1 text-gotic flex-no-shrink opacity-25"
                              />
                            )}
                            <blockquote
                              key={index}
                              className={classnames('', {})}
                            >
                              {slice.primary.quote[0].text}
                            </blockquote>
                          </div>
                        </div>
                      )
                    }

                    if (slice.type === 'quote') {
                      return (
                        <div className="flex items-start lg:my-16 " key={index}>
                          <img
                            src={quoteIcon}
                            alt=""
                            className="w-8 mb-2 mr-2 text-gotic flex-no-shrink"
                          />
                          <blockquote
                            key={index}
                            className="ml-2 lg:mr-12 text-xl text-blue-dianne"
                          >
                            {slice.primary.quote[0].text}
                          </blockquote>
                        </div>
                      )
                    }

                    if (slice.type === 'image' && slice.primary.image) {
                      return (
                        <div key={index} className="mt-16 mb-10">
                          <img
                            src={slice.primary.image.url}
                            alt={slice.primary.image.alt || ''}
                            className="db w-full"
                          />
                          {slice.primary.caption && (
                            <p className="mt-0 text-gothic font-sans text-center text-sm italic">
                              {slice.primary.caption}
                            </p>
                          )}
                        </div>
                      )
                    }

                    if (
                      slice.type === 'podcast' &&
                      slice.primary.soundcloud_embed
                    ) {
                      // @FIXME
                      return (
                        <div
                          key={index}
                          className="mt-16 mb-10"
                          dangerouslySetInnerHTML={{
                            __html: slice.primary.soundcloud_embed.html
                              .replace('visual=true', 'visual=false')
                              .replace('artwork=true', 'artwork=false')
                              .replace('height="400"', 'height="166"'),
                          }}
                        ></div>
                      )
                    }

                    return null
                  })}
                </article>
              )}
            </div>
            {article.highlights && article.highlights.length > 0 && (
              <div className="mt-8 lg:mt-0 lg:w-1/4 lg:ml-12">
                <div
                  className={classnames('bg-selago px-8 py-8 font-sans', {
                    'mt-16': article.expert,
                  })}
                >
                  <h2 className="font-serif font-normal pb-8 mb-8 border-dusty-gray border-b">
                    Highlights
                  </h2>
                  <ul className="leading-normal lg:pl-6">
                    {article.highlights.map((highlight, index) => (
                      <li className="mb-4 text-blue-dianne" key={index}>
                        {highlight.highlight_text}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            )}
          </div>
          {article.author && (
            <div className="lg:flex items-start justify-center">
              <div className="text-lg flex my-16 items-start px-8 lg:px-0 lg:w-30em">
                <div className="w-16 mr-4">
                  {article.author.photo && (
                    <img src={article.author.photo.url} alt="" />
                  )}
                </div>
                <div className="pt-2 font-sans text-gothic">
                  <p className="text-xs">Author</p>
                  <p className="text-xs mt-2">{article.author.name}</p>
                  <p className="text-xs mt-1 measure-narrow">
                    {article.author.title}
                  </p>
                </div>
              </div>
              <div className="hidden lg:block mt-8 lg:mt-0 lg:w-1/4 lg:ml-12 px-8 py-8 font-sans h-px" />
            </div>
          )}
        </div>

        <div className="max-w-5xl mx-auto mb-24 lg:mb-32">
          <HomePodcasts
            sectionTitle="Latest Podcasts"
            allLink="/podcasts"
            podcasts={latestPodcasts}
            showAllLink
          />
        </div>

        {article.expert && this.state.modalActive && (
          <AriaModal
            titleText="Expert"
            onExit={this.hideModal}
            underlayStyle={{}}
            underlayColor={false}
          >
            <div className="hidden lg:block max-w-md mt-16 bg-selago p-12">
              <div className="flex">
                <button
                  onClick={this.hideModal}
                  className="ml-auto focus:outline-none"
                >
                  <FontAwesomeIcon
                    icon={faTimes}
                    className="text-blue-dianne text-base"
                  />
                </button>
              </div>
              <div className="flex items-start text-blue-dianne">
                <div>
                  {this.state.expert.photo && (
                    <img
                      src={this.state.expert.photo.url}
                      alt=" "
                      className="w-24"
                    />
                  )}
                </div>
                <div className="ml-8">
                  <p className="font-sans text-sm">
                    {this.state.expert === article.expert
                      ? 'Interview with:'
                      : 'Expert:'}
                  </p>
                  <p className="font-serif text-xl mt-4">
                    {this.state.expert.name}
                  </p>
                  <p className="font-sans leading-normal mt-4 text-base">
                    {this.state.expert.title}
                  </p>
                </div>
              </div>
              {RichText.render(
                this.state.expert.full_bio,
                undefined,
                fullBioSerializer
              )}
            </div>
          </AriaModal>
        )}
      </Layout>
    )
  }
}

export default ArticlePage
